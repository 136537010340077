
    import __i18nConfig from '@next-translate-root/i18n'
    import __appWithI18n from 'next-translate/appWithI18n'
    import '../shared/page/src/components/widgets/__NBAIdGate/NBAIdWigetStyles.css';
import '@fontsource/inter/400.css';
import '@fontsource/inter/500.css';
import '@fontsource/inter/600.css';
import '@fontsource/inter/700.css';
import 'pure-react-carousel/dist/react-carousel.es.css';
import 'react-loading-skeleton/dist/skeleton.css';
import { AppHeadContents } from '../components/_app/components/AppHeadContents';
import { AppProviders } from '../components/_app/components/AppProviders';
import { InitialProps } from '../components/_app/types';
import { NextPage } from 'next';
import { PageProviders } from '../components/_app/components/PageProviders';
import { ReactElement, ReactNode } from 'react';
import { getPageProvidersProps, setLastPlatformUsedCookie, writePageResponseHeaders } from '../components/_app/utils/pageSSR';
import { useAppHooks } from '../components/_app/hooks';
import { useIsMounted } from '@dtx-company/true-common/src/hooks/useIsMounted';
import Head from 'next/head';
import _App, { AppContext, AppProps } from 'next/app';
export type NextPageWithLayout<P = Record<string, unknown>, IP = P> = NextPage<P, IP> & {
    getLayout?: (page: ReactElement) => ReactNode;
};
type AppPropsWithLayout = AppProps & InitialProps & {
    Component: NextPageWithLayout;
};
const App = ({ Component, pageProps, ...props }: AppPropsWithLayout): ReactNode => {
    useAppHooks();
    const isMounted = useIsMounted();
    const getLayout = Component.getLayout || (page => page);
    return (<div>
      <Head>
        <AppHeadContents pathname={props.router.pathname} page={pageProps?.page}/>
      </Head>
      <AppProviders>
        {/* PageProviders temporarily lives in App, but will be moved down to individual pages after getServersideProps has been added */}
        <PageProviders swrFallback={!isMounted() ? pageProps.swrFallback : undefined} {...props} {...pageProps}>
          {getLayout(<Component {...pageProps}/>)}
        </PageProviders>
      </AppProviders>
    </div>);
};
App.getInitialProps = async (context: AppContext): Promise<InitialProps> => {
    const appProps = await _App.getInitialProps(context);
    const { req, res, query } = context.ctx;
    const pageProps = await getPageProvidersProps(req, query);
    writePageResponseHeaders(req, res);
    setLastPlatformUsedCookie(req, res);
    return {
        ...appProps,
        ...pageProps
    };
};
const __Next_Translate__Page__194fbc61555__ = App;

    export default __appWithI18n(__Next_Translate__Page__194fbc61555__, {
      ...__i18nConfig,
      isLoader: true,
      skipInitialProps: false,
      loadLocaleFrom: __i18nConfig && __i18nConfig.loadLocaleFrom || ((l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default)),
    });
  